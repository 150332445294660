<template>
  <section class="mainText ">
    <b-container fluid="xl">
        <b-row id="main1" class="maintText__row">
            <b-col cols="12" class="pb-5 side-gap">
                <h2 style="mainText__mainName--maintxtx">
                    A filterek és a mentális egészség kapcsolata
                </h2>
                <p class="hashText">- Amikor a szemünknek sem hihetünk</p>
            </b-col>
        </b-row>
        <b-row class="maintText__row">
            <b-col cols="12" lg="6">
            <div class="maintText__txt-content pb-lg-5 pb-2">
                <p class="text">
                Társadalmunk nagyon erős nyomást helyez ránk azáltal, hogy már-már elvárja tőlünk azt, hogy mindig, minden pillanatban tökéletesen fessünk: legyen makulátlan a sminkünk, tökéletes a ruhánk, és szalonszépségű a hajunk. Éppen ezért egyáltalán nem meglepő, hogy az elvárásoknak való megfelelés céljából egyre többen nyúlnak különféle szépítő alkalmazásokhoz – hiszen valljuk be, mindannyian szeretnénk a legjobb formánkat mutatni, különösen a közösségi média felületeken. Azonban a retusálás és a képek szerkesztése nagyon komoly veszélyeket is rejthetnek magukban.</p>

                <p class="text">
                Kutatások szerint a francia, amerikai és angol fiatalok 90%-a (!) használ szépítő filtereket Snapchaten, és világszerte közel 600 millió ember voksol a képeik retusálása mellett Instagramon és Facebookon is. Általánosságban elmondható, hogy azok a fiatalok használnak gyakrabban beauty app-okat, akik alacsonyabb önbecsüléssel és rosszabb testképpel rendelkeznek – ezzel azonban kezdetét is veszi az ördögi kör, hiszen tovább mélyítik azt a meggyőződésüket, amely szerint nem elég szépek, karcsúak, nem elég fehér a foguk és hibátlan a bőrük. A következmény pedig gyakran étkezési zavarok, testdiszmorfia és depresszió lehetnek.  </p>

                <p class="text">
                Nagyon sokan beleesnek abba a csapdába, hogy szeretnének úgy kinézni, mint a szerkesztett önmaguk – holott ez teljességgel lehetetlen és szükségtelen! A szépítő alkalmazások irreális elvárásokat támasztanak felénk, így fontos, hogy próbáljunk tudatosan ellenállni az internet és a retusálás csábításainak! A következő tippek megfogadása segítségünkre lehet ebben:</p>
            </div>
            </b-col>
            <b-col cols="12" lg="6" class="order-4 order-lg-1">
              <img src="images/filterek.png" class="w-100 margin-top-sm-44 mb-4"/>
          </b-col>
            <b-col cols="12" lg="10" class="offset-lg-1 side-gap order-lg-2 order-1">
            <p class="text">
              <ul>
                <li class="py-3 d-sm-flex">
                  <span class="number">1</span>
                  <p class="text">-	Bár nem egyszerű, de fogadjuk el magunkat olyannak, amilyenek vagyunk: egyediek és megismételhetetlenek, és ez tesz minket különlegessé!</p> 
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">2</span>
                  <p class="text">-	Mérsékeljük a social media aktivitásunkat! Már öt Instagram-használattal eltöltött perc is bizonyíthatóan negatívan hathat az önképünkre.</p>
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">3</span>
                  <p class="text">-	Ha aktívan jelen vagyunk a social media felületeken, kövessünk inspiráló fiókokat, akik az önelfogadást hirdetik.</p>
                </li>
              </ul>
            </p>
            <p class="text">A fenti tanácsok elsőre apró lépésnek tűnhetnek, de a mentális egészségünk szempontjából annál fontosabb szerepet töltenek be – és az eredmény mindenképpen megéri!</p>
        </b-col>
        </b-row>
    </b-container>
    <Articles :isFirst="true"/>
  </section>
</template>
<script>
import Articles from '../components/Articles.vue'

export default {
  components: {
    Articles
  },
  mounted(){

    this.setMetaTags({        
        title: "A filterek és a mentális egészség kapcsolata", 
        desc: "Mindannyian szeretnénk a legjobb formánkat mutatni, különösen a közösségi média felületeken. Azonban a retusálás és a képek szerkesztése nagyon komoly veszélyeket is rejthetnek magukban.", 
        keywords: null, 
        ogType: 'website', 
        ogTitle: "A filterek és a mentális egészség kapcsolata",
        ogImage: null, 
        ogImageWidth: null, 
        ogImageHeight: null, 
        ogDesc: "Mindannyian szeretnénk a legjobb formánkat mutatni, különösen a közösségi média felületeken. Azonban a retusálás és a képek szerkesztése nagyon komoly veszélyeket is rejthetnek magukban."        
    })
  }
}
</script>